@use '@keyliving/component-lib/dist/scss/utils';
@use '@keyliving/component-lib/dist/scss/mixins' as *;

.wrapper {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 15;
    display: flex;
    background-color: #fff;
    justify-content: center;
    align-items: center;
}

.container {
    width: 100%;
    max-width: 600px;
    padding: 1rem;
    margin: 0 auto;
}

.container-end {
    display: flex;
    margin-top: 1rem;
    flex-direction: row;
    justify-content: center;
}

.slide {
    font-size: 0.875rem;
    color: utils.color('grey', 700);
    text-align: center;

    @include screen('md') {
        font-size: 1rem;
    }
}

.slide__title {
    font-family: utils.font-family('serif');
    font-size: 1.5rem;
    font-weight: font-weight('semibold');
    line-height: 1.2;

    @include screen('md') {
        font-size: 2.25rem;
    }
}

.slide__content {
    margin-top: 0.5rem;
    line-height: 1.5;

    @include screen('md') {
        margin-top: 1rem;
    }
}

.slide-img {
    position: relative;
    padding-top: 75%;
    margin-top: 2rem;
    overflow: hidden;
    background-color: #f6f8fa;
    border-radius: 7px;
}

.slide-img--welcome {
    background-color: #fff;
}

.slide-img__graphic {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: flex;
    padding: 1rem;
    flex-direction: column;
    justify-content: center;
}

.slide-img__graphic__img {
    width: 100%;
    height: auto;
}

/* stylelint-disable-next-line selector-pseudo-class-no-unknown */
:global(.swiper-pagination) {
    position: relative;
    top: 0;
    bottom: 0;
    padding: 1rem 0;
    text-align: center;
}

.bullet {
    display: inline-block;
    width: 0.75rem;
    height: 0.75rem;
    margin: 0 0.25rem;
    background-color: utils.color('grey', 200);
    border-radius: 999px;
}

.bullet--active {
    background-color: utils.color('royal', 400);
}
