@use '~@keyliving/component-lib/dist/scss/mixins' as *;

:root {
    --nav-width: 360px;
    --page-padding: 1rem;

    @include screen('xl5') {
        --nav-width: 420px;
    }
}

html,
body,
#root {
    min-height: 100vh;
}
