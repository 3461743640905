@use '~@keyliving/component-lib/dist/scss/utils';
@use '~@keyliving/component-lib/dist/scss/vars';

.link {
    font-weight: vars.$fw-semibold;
    color: utils.color('royal', 500);
}

.error-msg {
    margin: 1rem 0;
    color: utils.color('error');
    text-align: left;
}
